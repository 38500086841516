import { useRedirect } from "ra-core";
import * as React from "react";

const CRAWLER_LOGS_LINK = process.env.REACT_APP_CRAWLER_LOGS_LINK || "";

export const LogsList = () => {
  const redirect = useRedirect();

  React.useEffect(() => {
    window.open(CRAWLER_LOGS_LINK, "_blank", "noopener,noreferrer");
    redirect("list", "/users");
  }, []);

  return null;
};
