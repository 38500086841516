import {Edit, SimpleForm, TextInput, EmailField} from 'react-admin';

export const UserEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled source="id" />
      <EmailField source="email" />
      <TextInput source="username" />
    </SimpleForm>
  </Edit>
);
