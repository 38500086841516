const getCurrent = (Promise) => {
  try {
    const auth = JSON.parse(localStorage.getItem('auth'));
    const request = new Request(`${process.env.REACT_APP_API_URL}/auth/user/`, {
      headers: new Headers(
        {
          'accept': 'application/json',
          'Authorization': `Bearer ${auth['access_token']}`,
        }
      )
    });
    return fetch(request)
      .then(response => {
        if (response.status < 200 || response.status >= 300) {
          console.log(response);
        }
        return response.json();
      })
      .then(response => {
        if (typeof response.data === 'undefined' ||
          typeof response.data.attributes === 'undefined' ||
          response.data.attributes.role === 'undefined' ||
          response.data.attributes.role.length < 1) {
          return Promise.resolve({role: ['guest']});
        }
        const permissions = JSON.stringify(response.data.attributes);
        localStorage.setItem('permissions', permissions);

        localStorage.setItem('current', response.data.attributes.id);

        return Promise.resolve(response.data.attributes);
      });
  } catch (e) {
    return Promise.resolve({role: ['guest']});
  }
}

const authProvider = {
  login: ({username, password}) => {
    const data = `grant_type=&username=${username}&password=${password}&scope=&client_id=&client_secret=`
    const request = new Request(`${process.env.REACT_APP_API_URL}/auth/sign-in/?is_privileged=true`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: new Headers({
        'accept': 'application/json',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    });

    let response;
    return new Promise((resolve, reject) =>{
      fetch(request)
        .then(r => {
          response = r;
          return r.json();
        })
        .then(data => {
          if (response.ok) {
            localStorage.setItem('auth', JSON.stringify(data));
            resolve();
          } else {
            reject(`${(data && data.detail) || response.statusText}`);
          }
        })
        .catch((e) => console.log(e));
    });
  },
  logout: () => {
    localStorage.removeItem('auth');
    return Promise.resolve();
  },
  checkError: (error) => {
    const { status } = error;
    if (status === 401 || status === 403 || status === 422) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () => {
    return localStorage.getItem('auth')
      ? Promise.resolve()
      : Promise.reject({message: 'Login required'})
  },
  getPermissions: () => {
    let permissions = localStorage.getItem('permissions');

    try {
      permissions = JSON.parse(permissions);
    } catch (e) {
      return getCurrent(Promise);
    }
    if (!permissions) {
      return getCurrent(Promise);
    } else {
      return Promise.resolve(permissions);
    }
  },
};

export default authProvider;