import * as React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'react-final-form';
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslate, useLogin, useNotify, useSafeSetState} from 'ra-core';
import {useHistory} from 'react-router-dom';
import {Notification} from 'ra-ui-materialui';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  {name: 'RaLoginForm'}
);

const ResetPassword = (props) => {
  const {redirectTo} = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);
  const history = useHistory();
  const token = history.location.search.replace('?', '').split('=')[1];

  const [newPassword, setNewPassword] = React.useState('');
  const [errorNewPassword, setErrorNewPassword] = React.useState(null)

  const validate = (values) => {
    const errors = {username: undefined, password: undefined};
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = values => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                  ? error.message
                  : undefined,
          }
        );
      });
  };

  function submitNewPassword(event) {
    event.preventDefault();
    try {
      const request = new Request(`${process.env.REACT_APP_API_URL}/auth/reset-password/`, {
        method: 'PUT',
        headers: new Headers({
            'accept': 'application/json',
            'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          password: newPassword,
          token: token,
        }),
      });
      return fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            console.log(response);
          }
          return response.json();
        });
    } catch (e) {
      console.log(e);
    }

    console.log(newPassword, token);
    history.push('/login');
  }

  const changeNewPassword = (e) => {
    if (newPassword !== "") {
      setErrorNewPassword(null)
    }
    setNewPassword(e.target.value)
  }

  const blurInput = () => {
    if (newPassword === "") {
      setErrorNewPassword('Required')
    }
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({handleSubmit}) => (
        <>
          <form onSubmit={handleSubmit} noValidate>
            <div className={classes.form}>
              <div className={classes.input}>
                <TextField
                  id="password"
                  name="password"
                  type="password"
                  value={newPassword}
                  onChange={changeNewPassword}
                  onBlur={blurInput}
                  label={translate('New password')}
                  disabled={loading}
                  autoComplete="current-password"
                  fullWidth
                  error={errorNewPassword}
                  helperText={errorNewPassword}
                />
              </div>
            </div>
            <CardActions>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                className={classes.button}
                onClick={e => submitNewPassword(e)}
              >
                {loading && (
                  <CircularProgress
                    className={classes.icon}
                    size={18}
                    thickness={2}
                  />
                )}
                {translate('Change')}
              </Button>
            </CardActions>
          </form>
          <Notification/>
        </>
      )}
    />
  );
};

ResetPassword.propTypes = {
  redirectTo: PropTypes.string,
};

export default ResetPassword;
