import * as React from 'react';
import PropTypes from 'prop-types';
import {Field, Form} from 'react-final-form';
import {
  Button,
  CardActions,
  CircularProgress,
  TextField,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import {useTranslate, useLogin, useNotify, useSafeSetState} from 'ra-core';
import {Notification} from 'ra-ui-materialui';

const useStyles = makeStyles(
  (theme) => ({
    form: {
      padding: '0 1em 1em 1em',
    },
    input: {
      marginTop: '1em',
    },
    button: {
      width: '100%',
    },
    buttomForgot: {
      width: '290px',
      margin: "8px",
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
  {name: 'RaLoginForm'}
);

const Input = ({
                 meta: {touched, error}, // eslint-disable-line react/prop-types
                 input: inputProps, // eslint-disable-line react/prop-types
                 ...props
               }) => (
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...inputProps}
    {...props}
    fullWidth
  />
);

const LoginForm = (props) => {
  const {redirectTo} = props;
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const translate = useTranslate();
  const notify = useNotify();
  const classes = useStyles(props);

  const [showLoginPasswordForm, setShowLoginPasswordForm] = React.useState(false);
  const [emailRestore, setEmailRestore] = React.useState('');
  const [errorEmail, setErrorEmail] = React.useState('')

  const validate = (values) => {
    const errors = {username: undefined, password: undefined};

    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  const submit = values => {
    setLoading(true);
    login(values, redirectTo)
      .then(() => {
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        notify(
          typeof error === 'string'
            ? error
            : typeof error === 'undefined' || !error.message
              ? 'ra.auth.sign_in_error'
              : error.message,
          'warning',
          {
            _:
              typeof error === 'string'
                ? error
                : error && error.message
                  ? error.message
                  : undefined,
          }
        );
      });
  };

  function handleRestorePasswordLinkClick() {
    setShowLoginPasswordForm(true);
  }

  function goBackLoginForm() {
    setShowLoginPasswordForm(false);
  }

  function submitRestoreEmail(e) {
    try {
      const request = new Request(`${process.env.REACT_APP_API_URL}/auth/forgot-password/`, {
        method: 'POST',
        headers: new Headers({
          'accept': 'application/json',
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify({
          email: emailRestore
        }),
      });
      return fetch(request)
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            console.log(response);
          }
          notify('Check your email', { type: 'success', duration: 3, });
          return response.json();
        });
    } catch (e) {
      console.log(e);
    }
  }

  const changeEmail = (e) => {
    if (emailRestore !== "") {
      setErrorEmail(null)
    }
    setEmailRestore(e.target.value)
  }

  const blurInput = () => {
    if (emailRestore === "") {
      setErrorEmail('Required')
    }
  }

  return (
    <Form
      onSubmit={submit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          {!showLoginPasswordForm ?
            <>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    id="username"
                    name="username"
                    component={Input}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    id="password"
                    name="password"
                    component={Input}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                    autoComplete="current-password"
                  />
                </div>
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                >
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
              <Button
                type="submit"
                disabled={loading}
                className={classes.buttomForgot}
                onClick={handleRestorePasswordLinkClick}>
                {translate('Forgot Password?')}
              </Button>
            </> :
            <form onSubmit={submitRestoreEmail}>
              <div className={classes.form}>
                <div className={classes.input}>
                  <TextField
                    id="email"
                    name="email"
                    value={emailRestore}
                    onChange={changeEmail}
                    onBlur={blurInput}
                    label={translate('Email')}
                    disabled={loading}
                    fullWidth
                    error={errorEmail}
                    helperText={errorEmail}
                  />
                </div>
              </div>
              <CardActions>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={loading}
                  className={classes.button}
                  onClick={e => submitRestoreEmail(e)}
                >
                  {loading && (
                    <CircularProgress
                      className={classes.icon}
                      size={18}
                      thickness={2}
                    />
                  )}
                  {translate('send')}
                </Button>
              </CardActions>
              <Button
                type="submit"
                disabled={loading}
                className={classes.buttomForgot}
                onClick={goBackLoginForm}>
                {translate('Back')}
              </Button>
            </form>}
          <Notification/>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  redirectTo: PropTypes.string,
};

export default LoginForm;
