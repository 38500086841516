import {Admin, fetchUtils, Resource} from 'react-admin';
import {createBrowserHistory} from 'history';
import authProvider from './authProviderObj';
import simpleRestProvider from 'ra-data-simple-rest';
import {UsersList} from './users/UsersList';
import {UserEdit} from './users/UserEdit';
import LoginPage from './auth/Login.js';
import customRoutes from './customRoutes';
import {ProxiesList} from "./proxies/ProxiesList";
import { LogsList } from './Logs/LogsList';
import TerminalIcon from '@mui/icons-material/Terminal';
import GroupIcon from '@mui/icons-material/Group';

const history = createBrowserHistory();

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({Accept: 'application/json'});
  }

  const auth = JSON.parse(localStorage.getItem('auth'));
  options.user = {
    authenticated: true,
    token: `Bearer ${auth['access_token']}`,
  };
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = simpleRestProvider(process.env.REACT_APP_API_URL, httpClient);

const App = () => (
  <Admin
    authProvider={authProvider}
    dataProvider={dataProvider}
    loginPage={LoginPage}
    customRoutes={customRoutes}
    history={history}
  >
    <Resource name="users" list={UsersList} edit={UserEdit} icon={GroupIcon}/>
    <Resource name="proxies" list={ProxiesList}/>
    <Resource name="logs" list={LogsList} icon={TerminalIcon}/>
  </Admin>
);

export default App;
