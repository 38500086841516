import * as React from "react";
import { List, Datagrid, TextField, EmailField,  SimpleForm, BooleanField  } from 'react-admin';

export const ProxiesList = props => {

  return (
    <List {...props} >
      <Datagrid>
        <TextField source="id" />
        <TextField source="scheme" />
        <TextField source="host" />
        <TextField source="port" />
        <TextField source="login" />
        <TextField source="password" />
        <BooleanField source="enabled" />
        <BooleanField source="is_active" />
        <TextField source="last_checked" />
      </Datagrid>
    </List>
  );
}