import {List, Datagrid, EditButton, DeleteButton, TextField, Button, useRefresh, EmailField } from "react-admin";
import {ChipField as ChipFieldOriginal, useNotify} from "react-admin";
import {useState} from "react";
import ChangePassword from "../ChangePassword/ChangePassword";

function ChipField(props) {
  const arrayOfProps = props.record.roles.map(({ name }) => ({ ...props, record: { ...props.record, roles: name} }));
  return(arrayOfProps.map((el, index) => <ChipFieldOriginal {...el} key={el.record.id + index}/>));
}

const PromoteButton = ({record}) => {
  const refresh = useRefresh();
  const notify = useNotify();

  return (<Button
    label="Promote"
    onClick={() => {
      const roles = record.roles.map(({ name }) => name);
      if (roles.includes('admin')) {
        notify(`This user is already admin`, { type: 'success', duration: 3, });
      }
      try {
        const auth = JSON.parse(localStorage.getItem('auth'));
        const request = new Request(`${process.env.REACT_APP_API_URL}/users/role/${record.id}`, {
          method: 'POST',
          headers: new Headers({
              'accept': 'application/json',
              'Authorization': `Bearer ${auth['access_token']}`,
            }
          )
        });
        return fetch(request)
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              console.log(response);
            }
            refresh();
            return response.json();
          });
      } catch (e) {
        console.log(e);
      }
    }}
  />)
};

const DemoteButton = ({record}) => {
  const refresh = useRefresh();
  const notify = useNotify();

  return (<Button
    label="Demote"
    onClick={() => {
      const roles = record.roles.map(({ name }) => name);
      if ((roles.includes('user') && roles.length === 1) ||
        (roles.includes('user') && roles.includes('superadmin') && roles.length === 2)) {
        notify(`This user can't be demoted`, { type: 'success', duration: 3, });
      }
      try {
        const auth = JSON.parse(localStorage.getItem('auth'));
        const request = new Request(`${process.env.REACT_APP_API_URL}/users/role/${record.id}`, {
          method: 'DELETE',
          headers: new Headers({
              'accept': 'application/json',
              'Authorization': `Bearer ${auth['access_token']}`,
            }
          )
        });
        return fetch(request)
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              console.log(response);
            }
            refresh();
            return response.json();
          });
      } catch (e) {
        console.log(e);
      }
    }}
  />)
};

const ChangePasswordButton = ({ record }) => {
  const [ isModalActive, setModalActive ] = useState(false);

  return (
    <>
      <Button
        label="Change Password"
        onClick={() => setModalActive(true)}
      />
      <ChangePassword active={isModalActive} setActive={setModalActive} record={record}/>
    </>
  );
};


export const UsersList = props => {
  return (
    <List {...props} >
      <Datagrid>
        <TextField source="id"/>
        <TextField source="username"/>
        <EmailField source="email"/>
        <ChipField source="roles"/>
        <PromoteButton record={props}/>
        <DemoteButton record={props}/>
        <ChangePasswordButton record={props} />
        <EditButton/>
        <DeleteButton/>
      </Datagrid>
    </List>
  );
};
