import React, {useState} from "react";
import "./ChangePassword.css";

export default function ChangePassword({ active, setActive, record }) {
  const [password, setPassword] = useState('');
  const closeModal = () => setActive(false);
  const onChangePasswordInput = (event) => setPassword(event.currentTarget.value);

  const onClickChangeButton = () => {
      try {
        const auth = JSON.parse(localStorage.getItem('auth'));
        const request = new Request(`${process.env.REACT_APP_API_URL}/users/password/${record.id}`, {
          method: 'PUT',
          headers: new Headers({
            'accept': 'application/json',
            'Authorization': `Bearer ${auth['access_token']}`,
            'Content-Type': 'application/json'
            }
          ),
          body: `{"password": "${password}"}`
        });
        closeModal();
        return fetch(request)
          .then(response => {
            if (response.status < 200 || response.status >= 300) {
              console.log(response);
            }
            return response.json();
          });
      } catch (e) {
        console.log(e);
      }
    };

  return (
    <div className={ active ? "modal-change-password active" : "modal-change-password"} onClick={closeModal}>
      <div
        className="modal-change-password--content MuiPaper-root MuiCard-root RaEdit-card-232 MuiPaper-elevation1 MuiPaper-rounded"
        onClick={(e) => e.stopPropagation()}
      >
        <span className="modal-change-password--title MuiLBase-root">
           Change password for {record.username}
        </span>
        <input
            value={password}
            style={{backgroundColor: '#f5f5f5', padding: '10px'}}
            className="modal-change-password__input MuiInputBase-input MuiInputBase-inputMarginDense"
            type="password"
            name="password"
            placeholder="Enter new password"
            onChange={onChangePasswordInput}
          />
        <div className="modal-change-password__button-container MuiToolbar-root MuiToolbar-regular RaToolbar-toolbar-240 RaToolbar-desktopToolbar-241 MuiToolbar-gutters">
          <button
            type="button"
            className="modal-change-password__button-cancel MuiButtonBase-root MuiButton-root MuiButton-text RaButton-button-138 ra-delete-button RaDeleteWithUndoButton-deleteButton-224 MuiButton-textPrimary MuiButton-textSizeSmall MuiButton-sizeSmall"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="button"
            className="modal-change-password__button-change MuiButtonBase-root MuiButton-root MuiButton-contained RaSaveButton-button-113 MuiButton-containedPrimary"
            onClick={onClickChangeButton}
          >
            Change
          </button>
        </div>
      </div>
    </div>
  )
}
